import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"


const TwitterAPIProOverviewCostsCampaignImpactsForMarketers = ({
    location
  }) => {
  const title =
    "Twitter API Pro：担当者が知っておくべき概要、料金、キャンペーンへの影響とは？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitter API Pro：担当者が知っておくべき概要、料金、キャンペーンへの影響とは？"
          pagedesc="マーケティング担当者必見！Twitter API Proプランの概要、料金体系、Basicとの違いを解説。さらにTwitterキャンペーンへの影響や選択ポイントも詳しく掘り下げています。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230528/230528-1.jpg"
          pagepath="/blog/Twitter-API-Pro-Overview-Costs-Campaign-Impacts-for-Marketers"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              Twitter API
              Pro：担当者が知っておくべき概要、料金、キャンペーンへの影響とは？
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年5月28日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="Twitterのアイコン"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230528/230528-1.jpg"
                className="w-full"
              />
            </figure>
            <h2 id="id1">Twitter API Proプランがスタート</h2>
            <p>
              2023年2月にTwitter 2023年4月より有料化されたTwitter
              API。一部のウェブサービスが終了したり、プランに制限が設けられたりと、大きな影響を及ぼしています。そんな中、Twitter
              APIの料金体系に新たなプランが追加されたという発表がありました。
            </p>
            <h2 id="id2">インスタントウィンキャンペーンへの影響は？</h2>
            <p>
              Twitterは企業のマーケティングにおける強力なツールであり、その能力を最大限に引き出すための鍵の一つがTwitter
              APIです。このAPI利用に新プランが加わるとなると、開発知識を持たないマーケティング担当者でも、キャンペーンを開催する際にどのような影響があるのかを理解することが重要となります。
            </p>
            <p>
              この記事では、マーケティング担当者が理解すべきTwitter API
              Proの詳細や既存プランとの違い、そしてTwitterインスタントウィンやハッシュタグキャンペーンへの影響について説明します。
            </p>
            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>Twitter API Pro プランについてざっと概要を知りたい方</li>
              <li>
                インスタントウィンキャンペーンを予定中のマーケティング担当者
              </li>
              <li>Twitterを使って効率的に販促をしたい企業</li>
            </ul>
            <p>
              ※記載内容は、当記事公開時の情報に基づきます。最新の情報については、
              <a
                href="https://developer.twitter.com/en/products/twitter-api"
                target="_blank"
                rel="noreferrer"
              >
                Twitter Developer Platform, Products, Twitter APIのページ
              </a>
              をご確認ください。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitter API Proプランがスタート
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    インスタントウィンキャンペーンへの影響は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. Twitter API Pro の概要
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        Twitter API Proでできること
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        今後はFree、Basic、Pro、Enterpriseの4プラン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        料金体系
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    2. BasicプランとProプランの違い
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ProはBasicよりもAPI利用可能量が増大
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        GET /2/tweets/:id/retweeted_by
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        GET /2/users/:id/followers
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        GET /2/tweets/:id/liking_users
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        POST /2/dm_conversations
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    3. Twitterキャンペーンへの影響
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        API Proなら、大規模なインスタントウィンを開催可能
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        API Proなら、応募者全員へアプローチ可能
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        API Proなら、ハッシュタグ投稿の収集件数が大幅に増加
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id17" className="toc__link">
                    4. API BasicとPro、どちらにするか迷ったら
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id18" className="toc__link">
                        1ヶ月あたりの開催予算はいくらか
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id19" className="toc__link">
                        1日あたりの当選者は何名か
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        応募者全員にアプローチが必要か
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id21" className="toc__link">
                        無料相談で最適なAPIプランをご提案します
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id22" className="toc__link">
                    5. 自社にあったキャンペーンでより良い販促を
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id23" className="toc__link">
                        Twitterキャンペーンでファンと出会おう
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. Twitter API Pro の概要</h2>
              <p>
                まずは、2023年5月26日に発表されたTwitter API
                Proの概要を説明します。
              </p>
              <blockquote className="twitter-tweet">
                <p lang="en" dir="ltr">
                  📣 Calling all start-ups 📣
                  <br />
                  <br />
                  Today we are launching our new access tier, Twitter API Pro!
                  <br />
                  <br />
                  Experiment, build, and scale your business with 1M Tweets per
                  month, including our powerful real-time Filtered/Stream and
                  Full Archive Search endpoints. We look forward to seeing what
                  you…
                </p>
                &mdash; Twitter Dev (@TwitterDev)
                <a href="https://twitter.com/TwitterDev/status/1661790253886177280?ref_src=twsrc%5Etfw">
                  May 25, 2023
                </a>
              </blockquote>
              <p>
                （引用元　
                <a
                  href="https://twitter.com/TwitterDev/status/1661790253886177280?s=20"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://twitter.com/TwitterDev/status/1661790253886177280?s=20
                </a>
                ）
              </p>
              <div className="blog__border-highlight">
                <p>
                  日本語訳：
                  <br />
                  📣すべてのスタートアップのみなさまへ📣
                  <br />
                  <br />
                  本日、新しいアクセスプランである Twitter API Pro
                  をリリースします。
                  <br />
                  強力なリアルタイムのフィルター/ストリーム検索エンドポイントやフル
                  アーカイブ検索エンドポイントを含め、月間 100
                  万件のツイートであなたのビジネスを実験、構築、拡張できます。次に何を実現するか楽しみにしています。
                  <br />
                  <br />
                  こちらから今すぐ登録してください：
                  <a
                    href="https://developer.twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://developer.twitter.com
                  </a>
                </p>
              </div>

              <h3 id="id4">Twitter API Proでできること</h3>
              <p>
                <a
                  href="https://developer.twitter.com/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter APIの説明ページ
                </a>
                によると、Twitter API Proでは以下のことが可能です。
              </p>
              <img
                placeholder="tracedSVG"
                alt="Twitter API Proでできること"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/230528/230528-2.png"
                className="mb-4"
              />
              <p>
                赤枠は筆者による。引用元：
                <a
                  href="https://developer.twitter.com/en/docs/twitter-api"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter Developer Platform, Documentation, Twitter APIのページ
                </a>
              </p>
              <p className="font-bold">
                Pro
                <br />
                <br />
                For startups scaling their business
              </p>
              <ul className="font-bold">
                <li>
                  Rate-limited access to suite of v2 endpoints, including search
                  and filtered stream
                </li>
                <li>1,000,000 Tweets per month - GET at the app level</li>
                <li>
                  300,000 Tweets per month - posting limit at the app level
                </li>
                <li>3 app IDs</li>
                <li>Login with Twitter</li>
                <li>$5,000 per month</li>
              </ul>
              <p>
                (日本語訳)
                <br />
                ビシネスを拡大中のスタートアップ向け
              </p>
              <ul>
                <li>
                  v2エンドポイントの一連のアクセスに対するレート制限、検索およびフィルタードストリームを含む
                </li>
                <li>月間100万ツイート - Appレベルでの取得</li>
                <li>月間30万ツイート - Appレベルでの投稿制限</li>
                <li>3つのApp ID 作成可能</li>
                <li>Twitter ログイン利用可能</li>
                <li>5,000ドル/月（約70万円/月）</li>
              </ul>

              <h3 id="id5">今後はFree、Basic、Pro、Enterpriseの4プラン</h3>
              <p>
                上記ページですでに更新されている通り、今後Twitter
                APIを利用するには4プランから選ぶことになります。
              </p>

              <h3 id="id6">料金体系</h3>
              <p>
                Twitter
                APIは、プランによって同じ機能でも利用可能な量（アクセスできる回数）に差があります。例えば、同じDM送信機能でも、ベーシックとエンタープライズでは1日の送信上限に違いが生じるなどです。
              </p>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">　</th>
                    <th className="blog__text-center">Free</th>
                    <th className="blog__text-center">Basic</th>
                    <th className="blog__text-center">Pro</th>
                    <th className="blog__text-center">Enterprise</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold">月額料金</td>
                    <td>無料</td>
                    <td>
                      100ドル/月
                      <br />
                      約1.3万円/月
                    </td>
                    <td>
                      5,000ドル/月
                      <br />
                      約70万円/月
                    </td>
                    <td>
                      （42,000ドル〜/月）
                      <br />
                      （約580万円~/月）
                      <br />※ 参考額
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                Proプランの登場で、大きく差が開いていたBasicとEnterpriseの間が埋められることになりました。
              </p>
              <p>
                APIについて基礎知識が知りたい方は、ぜひ以下の記事もご覧ください。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-api-basic-guide-for-marketers"
                    target="_blank"
                    rel="noreferrer"
                  >
                    マーケ担当者のためのTwitter API解説｜APIとは？Blueと違うの？
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id7">2. BasicプランとProプランの違い</h2>
              <h3 id="id8">ProはBasicよりもAPI利用可能量が増大</h3>
              <p>
                Proプランでは、インスタントウィンのようなキャンペーンで頻繁に利用されるAPI機能の使用量が大幅に増えます。
              </p>
              <ul>
                <li>
                  RT（リツイート）の頻繁なチェックが可能:
                  Basicプランと比較して、Proプランでは1分間に最大5回までチェックを行うことが可能です。
                </li>
                <li>
                  フォローチェックも高頻度に実行可能:
                  Basicプランでは時間制限があるのに対し、Proプランではほぼ毎分の取得が可能になります。
                </li>
                <li>
                  DM（ダイレクトメッセージ）の自動送信が大幅に増加:
                  Basicプランでは1日に最大50件までですが、Proプランでは1日に最大1,000件まで送信可能になります。
                </li>
              </ul>
              <p>
                以下の詳細は、Twitter Developer Portal, Products, Twitter API
                v2のBasic Featuresを基に抜粋しています。
              </p>

              <h3 id="id9">GET /2/tweets/:id/retweeted_by</h3>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">Basic</th>
                    <th className="blog__text-center">Pro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      5 requests / 15 mins
                      <br />
                      PER APP
                    </td>
                    <td>
                      75 requests / 15 mins
                      <br />
                      PER APP
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <td>
                      5 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                    <td>
                      75 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                  </tr>
                  <tr>
                    <td>Tweet cap：yes</td>
                    <td>Tweet cap：yes</td>
                  </tr>
                </tbody>
              </table>

              <h3 id="id10">GET /2/users/:id/followers</h3>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">Basic</th>
                    <th className="blog__text-center">Pro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-bold">
                    <td>
                      100 requests / 24 hours
                      <br />
                      PER USER
                    </td>
                    <td>
                      15 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                  </tr>
                  <tr>
                    <td>
                      500 requests / 24 hours
                      <br />
                      PER APP
                    </td>
                    <td>
                      15 requests / 15 mins
                      <br />
                      PER APP
                    </td>
                  </tr>
                  <tr>
                    <td>Tweet cap：no</td>
                    <td>Tweet cap：no</td>
                  </tr>
                </tbody>
              </table>

              <h3 id="id11">GET /2/tweets/:id/liking_users</h3>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">Basic</th>
                    <th className="blog__text-center">Pro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-bold">
                    <td>
                      5 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                    <td>
                      75 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                  </tr>
                  <tr>
                    <td>
                      25 requests / 15 mins
                      <br />
                      PER APP
                    </td>
                    <td>
                      75 requests / 15 mins
                      <br />
                      PER APP
                    </td>
                  </tr>
                  <tr>
                    <td>Tweet cap：no</td>
                    <td>Tweet cap：no</td>
                  </tr>
                </tbody>
              </table>

              <h3 id="id12">POST /2/dm_conversations</h3>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">Basic</th>
                    <th className="blog__text-center">Pro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-bold">
                    <td>
                      5 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                    <td>
                      200 requests / 15 mins
                      <br />
                      PER USER
                    </td>
                  </tr>
                  <tr>
                    <td>
                      500 requests / 24 hours
                      <br />
                      PER APP
                    </td>
                    <td>
                      2500 requests / 24 hours
                      <br />
                      PER APP
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <td>
                      50 requests / 24 hours
                      <br />
                      PER USER
                    </td>
                    <td>
                      1000 requests / 24 hours
                      <br />
                      PER USER
                    </td>
                  </tr>
                  <tr>
                    <td>Tweet cap：no</td>
                    <td>Tweet cap：no</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h2 id="id13">3. Twitterキャンペーンへの影響</h2>
              <p>
                それでは、Proプランを活用することでどのようなキャンペーンが実現可能になるのか、詳しく見ていきましょう。
              </p>

              <h3 id="id14">
                API Proなら、大規模なインスタントウィンを開催可能
              </h3>
              <p>
                Twitter API
                Proを利用することで、多数の当選者を設定したキャンペーンが実現可能となります。
              </p>
              <p>
                具体的には、Basicプランでは1日あたり最大50人へのDM送信が可能なのに対し、Proプランでは1,000人へと大幅に増加します。Basicプランでは自動抽選で選ばれた当選者にだけDM通知を送る運用が主ですが、Proプランでは多くのユーザーへメッセージを送ることが可能となります。これにより、キャンペーンへの参加意欲が高い顧客に対して、ブランドからのメッセージや提案を倍増させるチャンスを得ることができます。
              </p>

              <h3 id="id15">API Proなら、応募者全員へアプローチ可能</h3>
              <p>
                Twitter API
                Proを利用することで、全ての応募者へブランドのメッセージを届けることができます。
              </p>
              <p>
                Basicプランでは当選者へのDM送信に加え、フォローとリツイートチェックにも時間制限が存在します。しかし、Proプランではタイムラグなしでフォローとリツイートチェックが可能であり、またDMをより多く送ることが可能となります。結果として、抽選結果に関わらず、何らかの形で応募者全員へアプローチすることが可能となります。（ただし、DMが1,000件の上限に達した後は、応募者はツイート内URLから結果ページにて確認する形となります。）
              </p>
              <p>
                これにより、例えば、ハズレたユーザーへもクーポンを配布するなど、販促活動を加速させることが可能となります。
              </p>

              <h3 id="id16">
                API Proなら、ハッシュタグ投稿の収集件数が大幅に増加
              </h3>
              <p>
                Twitter API
                Proを利用すると、ハッシュタグによる投稿の収集件数も大幅に増えます。
              </p>
              <p>
                Basicプランでは月間1万件までの収集が可能なのに対し、Proプランでは月間最大100万件まで収集が可能となります。大規模なハッシュタグキャンペーンで多くの応募が予想される場合や、ユーザー生成コンテンツ（UGC）を最大限に活用し、多くのユーザー投稿を収集したい場合、Proプランの利用が有効となりそうです。
              </p>
            </div>
            <div>
              <h2 id="id17">4. API BasicとPro、どちらにするか迷ったら</h2>
              <p>
                ここまで詳しく説明してきましたが、自社にとって最適なAPIプランを選ぶのが難しいと感じている担当者もいらっしゃることでしょう。そこで、選択のポイントをいくつかご紹介します。
              </p>
              <p>
                （Freeプランは「Twitterログイン」および「投稿する」機能のみ利用可能で、投稿上限は1,500件/月となっており、非常に限定的です。また、Enterpriseプランは別途API利用手続きが必要です。したがって、ここではBasicとProの2つから選択することを前提に話を進めます。）
                <br />
                （また、キャンペーンは基本的にフォロー＆RT応募で即時抽選のインスタントウィン形式を前提としています。）
              </p>
              <h3 id="id18">1ヶ月あたりの開催予算はいくらか</h3>
              <p>
                景品代を除いた予算が100万円以上の場合、API
                Proプランがおすすめです。
              </p>
              <p>
                できるだけコストを抑えたい場合は、API
                Basicプランが良いでしょう。
              </p>

              <h3 id="id19">1日あたりの当選者は何名か</h3>
              <p>
                1日あたり50名以上にDMを送りたい（ = 当選させたい）場合は、API
                Pro契約が必要です。
              </p>
              <p>
                当選者が50名以下であれば、API
                Basicでキャンペーンを開催可能です。
              </p>

              <h3 id="id20">応募者全員にアプローチが必要か</h3>
              <p>
                当たりの場合もハズレの場合もアプローチしたい場合は、API
                Pro契約が必要です。
              </p>
              <p>
                当選者にのみ即時DMで良い場合は、API
                Basicでキャンペーン実施が可能です。
              </p>

              <h3 id="id21">無料相談で最適なAPIプランをご提案します</h3>
              <p>
                「そもそもどのような機能が必要かわからない」「デジタルクーポンを使ってカスタマイズしたいけどBasicで開催できる？」など様々なお悩みがあるかと思います。
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  PARKLoTの無料相談
                </a>
                では、貴社の課題からキャンペーン内容まで幅広くヒアリングし、最適なプランをご提案いたします。
              </p>
            </div>
            <div>
              <h2 id="id22">5. 自社にあったキャンペーンでより良い販促を</h2>
              <h3 id="id23">Twitterキャンペーンでファンと出会おう</h3>
              <p>
                Twitterの高い拡散性をマーケティングに活用しない手はありません。ユーザーが楽しむことができる施策、例えばインスタントウィンなどを、効果的にAPIを活用したり、抽選ツールを導入したりすることで実現しましょう。これにより新しい顧客との出会いを得られ、既存のファンに対してもブランドへの愛着を一層深めてもらえるでしょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </p>
              </div>
            </div>
            <br />
            <HubSpotFormComponent />
            <br />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/twitter-api-basic-guide-for-marketers"
                    target="_blank"
                    rel="noreferrer"
                  >
                    マーケ担当者のためのTwitter API解説｜APIとは？Blueと違うの？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid"
                    target="_blank"
                    rel="noreferrer"
                  >
                    キャンペーンと好相性！UGCの活用方法や注意点について解説【Twitter/Instagram】
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-get-followers-enterprise"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【今すぐ実践！】Twitter企業アカウントでフォロワーを増やす方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default TwitterAPIProOverviewCostsCampaignImpactsForMarketers
